/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import * as React from "react"
import type { GatsbyBrowser } from "gatsby"
import * as fv from "focus-visible";
import * as fw from "focus-within-polyfill";

import "./src/assets/sass/reset.scss";
import "./src/assets/sass/general.scss";
import "shared/dist/fonts.css";
import "shared/dist/style.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { SearchContextProvider } from "./src/apps/website/hooks/useSearchContext";
import { LiveChatContextProvider } from "./src/components/live-chat/useLiveChatContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const wrapRootElement: GatsbyBrowser["wrapPageElement"] = ({ element }) => (
  <LiveChatContextProvider>
    <QueryClientProvider client={queryClient}>
      <SearchContextProvider>
        {element}
      </SearchContextProvider>
    </QueryClientProvider>
  </LiveChatContextProvider>
);
